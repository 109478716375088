import { Component, OnInit, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { VitalsPageService } from 'src/app/modules/task-module/vitals-page/vitals-page.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { AppointmentService } from '../appointment/appointment.service';


@Component({
  selector: 'app-spirometer',
  templateUrl: './spirometer.component.html',
  styleUrls: ['./spirometer.component.scss']
})


export class SpirometerComponent implements OnInit {
  pdfdatastore: any;
  Highcharts: typeof Highcharts = Highcharts;
  spiroChart: Highcharts.Chart | undefined;
  spiroSeries: Highcharts.Series | undefined;


  spiroLiveSeries: any = [];
  spiroSeriesData: any = [];
  spiroReadingCount: any = 0;
  spirotable_count: any = 0;

  isFirstGraphShowing: any = true;


  constructor(public api: VitalsPageService, private renderer: Renderer2, private el: ElementRef, private service: AppointmentService) {

    this.api.spiroChartData.subscribe((response: any) => {
      console.log("spirometer hit Saheb");
      console.log("spirometer hit and response Saheb :" + response);

      if (response == 'emit') {
        this.createSpiroLiveGraph('');
      }
      else if (response == 'emitTableData') {
        this.generateSpiroSeriesData('');
      }
    }, (error: any) => {
      console.error("Error in subscription:", error); // Log any error from the observable
    })

    this.api.chartClose.subscribe((response: any) => {

      if (response === 'clear') {
        this.api.spiroMeterReadings = [];
        this.spiroLiveSeries = [];

        this.api.spiroMeterReadingsForTable = [];
        this.spiroSeriesData = [];
        this.spiroReadingCount = 0;
        this.spirotable_count = 0;
      }
    })
  }
  ngOnInit(): void {
  }

  createSpiroLiveGraph(datas: string): void {
    this.isFirstGraphShowing = true;
    let arr: number[] = this.api.spiroMeterReadings;

    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        this.spiroLiveSeries.push(arr[i]);
      }
    }

    if (this.spiroLiveSeries.length > 0) {
      this.showLiveSpiroGraph();
    }


  }

  showLiveSpiroGraph(): void {
    // const container = document.getElementById('spiroLiveGraph');
    // console.log('Container:', container); // Add this line to check if the container exists

    // if (container) {
    Highcharts.chart('spiroLiveGraph', {
      chart: {
        type: 'spline',
        events: {
          load: () => {
            if (this.spiroChart) {
              this.spiroSeries = this.spiroChart.series[0];
            }
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        tickPixelInterval: 100,
        min: 0,
        max: 6000
      },

      yAxis: {
        min: 0,
        max: 16777215,
        tickInterval: 5000,
        visible: false,
        title: {
          text: 'Flow'
        }
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: false
        }
      },
      series: [{
        type: 'spline',
        color: 'blue',
        data: this.spiroLiveSeries
      }],
      scrollbar: {
        enabled: true,
        barBackgroundColor: 'gray',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: 'gray',
        buttonBorderWidth: 0,
        buttonArrowColor: 'yellow',
        buttonBorderRadius: 7,
        rifleColor: 'yellow',
        trackBackgroundColor: 'white',
        trackBorderWidth: 1,
        trackBorderColor: 'silver',
        trackBorderRadius: 7
      }
    } as Highcharts.Options);
    // } else {
    //   console.error('Chart container not found');
    // }


  }

  generateSpiroSeriesData(datas: string): void {
    this.isFirstGraphShowing = false;

    let arr: any = this.api.spiroMeterReadingsForTable;

    if (this.api.spiroMeterReadingsForTable.length >= 3) {

      this.spiroSeriesData = this.mergeArray(arr[0], arr[1]);

      if (this.spiroSeriesData.length > 0) {
        this.plotSpiroChart();
      }


      this.spiroReadingCount++;
      var colors = ["#00ff00", "#0000ff", "#ff0000", "#d341f4", "#f4418b"];

      let tableId = "Table_" + this.spiroReadingCount;
      this.clearTables();
      if (arr[2].length > 0) {
        this.createSpiroTable(arr[2], colors[this.spiroReadingCount - 1], tableId);
      }
    }

  }

  private clearTables(): void {
    const spiroTable1 = this.el.nativeElement.querySelector('#spiroTable1');
    const spiroTable2 = this.el.nativeElement.querySelector('#spiroTable2');
    const spiroTable3 = this.el.nativeElement.querySelector('#spiroTable3');

    if (spiroTable1) {
      while (spiroTable1.firstChild) {
        spiroTable1.removeChild(spiroTable1.firstChild);
      }
    }
    if (spiroTable2) {
      while (spiroTable2.firstChild) {
        spiroTable2.removeChild(spiroTable2.firstChild);
      }
    }
    if (spiroTable3) {
      while (spiroTable3.firstChild) {
        spiroTable3.removeChild(spiroTable3.firstChild);
      }
    }
  }
  createSpiroTable(tableData: number[], color: string, tableId: string) {

    this.spirotable_count++;
    const len = 3;
    const FVC = tableData[0].toFixed(len);
    const FEV1 = tableData[1].toFixed(len);
    const FEV1_per = tableData[2].toFixed(len);
    const FEF25 = tableData[3].toFixed(len);
    const FEF50 = tableData[4].toFixed(len);
    const FEF75 = tableData[5].toFixed(len);
    const PEF = tableData[6].toFixed(len);
    const TV = tableData[7].toFixed(len);
    const TLC = tableData[8].toFixed(len);

    console.log("inside createSpiroTable spirotable_count...." + this.spirotable_count);

    const table = this.renderer.createElement('table');
    this.renderer.setAttribute(table, 'id', tableId);
    this.renderer.setAttribute(table, 'border', '1');
    this.renderer.setStyle(table, 'width', '100%');
    this.renderer.setStyle(table, 'table-layout', 'fixed');
    const tbody = this.renderer.createElement('tbody');
    table.appendChild(tbody);

    const tr1 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr1, 'display', 'flex');
    tbody.appendChild(tr1);

    this.appendCell(tr1, 'FVC', FVC + ' L', color);
    this.appendCell(tr1, 'FEV1', FEV1 + ' L', color);
    this.appendCell(tr1, 'FEV1%', FEV1_per, color);

    const tr2 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr2, 'display', 'flex');
    tbody.appendChild(tr2);

    this.appendCell(tr2, 'FEF25', FEF25 + ' L/sec', color);
    this.appendCell(tr2, 'FEF50', FEF50 + ' L/sec', color);
    this.appendCell(tr2, 'FEF75', FEF75 + ' L/sec', color);

    const tr3 = this.renderer.createElement('tr');
    this.renderer.setStyle(tr3, 'display', 'flex');
    tbody.appendChild(tr3);

    this.appendCell(tr3, 'PEF', PEF + ' L/sec', color);
    this.appendCell(tr3, 'TV', TV + ' L', color);
    this.appendCell(tr3, 'TLC', TLC + ' L', color);

    if (this.spirotable_count === 1) {
      const spiroTable1 = this.el.nativeElement.querySelector('#spiroTable1');
      if (spiroTable1) {
        this.renderer.appendChild(spiroTable1, table);
      }
      spiroTable1.style.display = 'block';
    }
    if (this.spirotable_count === 2) {
      const spiroTable2 = this.el.nativeElement.querySelector('#spiroTable2');
      if (spiroTable2) {
        this.renderer.appendChild(spiroTable2, table);
      }
      spiroTable2.style.display = 'block';
    }
    if (this.spirotable_count === 3) {
      const spiroTable3 = this.el.nativeElement.querySelector('#spiroTable3');
      if (spiroTable3) {
        this.renderer.appendChild(spiroTable3, table);
      }
      spiroTable3.style.display = 'block';
    }


    setTimeout(() => {
      this.generatePdf();
    }, 0.500); //
  }

  private appendCell(row: HTMLElement, label: string, value: string, color: string) {
    const th = this.renderer.createElement('th');
    this.renderer.setStyle(th, 'color', '#6d0419');
    this.renderer.setStyle(th, 'border', '1px solid black');
    this.renderer.setStyle(th, 'font-size', '12px');
    this.renderer.setStyle(th, 'width', '33.33%');  // Set the desired width
    th.innerText = label;
    row.appendChild(th);

    const td = this.renderer.createElement('td');
    this.renderer.setStyle(td, 'color', color);
    this.renderer.setStyle(td, 'font-weight', 'bold');
    this.renderer.setStyle(td, 'border', '1px solid black');
    this.renderer.setStyle(td, 'font-size', '12px');
    this.renderer.setStyle(td, 'width', '33.34%');  // Set the desired width
    td.innerText = value;
    row.appendChild(td);
  }


  mergeArray(x: [], y: []) {
    var xy = [];
    for (var i = 0; i < x.length && i < y.length; i++) {
      xy[i] = [x[i], y[i]];
    }

    return xy;
  }

  plotSpiroChart(): void {

    Highcharts.chart('spiroGraph', {
      xAxis: {
        minPadding: 0.05,
        maxPadding: 0.05,
        title: {
          text: 'Volume (L)',
          style: {
            fontSize: '18px'
          }
        },
      },
      yAxis: {

        title: {
          text: 'Flow ( liters per second )',
          //align: 'center'
          style: {
            fontSize: '18px'
          }
        }
      },
      title: {
        text: ''
      },
      plotOptions: {
        series: {
          allowPointSelect: true,
          showInLegend: false
        }
      },
      // series: this.spiroSeriesData
      series: [{
        type: 'spline',
        color: 'green',
        data: this.spiroSeriesData
      }]
    } as Highcharts.Options);
  }

  //  generatePdf(){
  //   const doc = new jsPDF();
  //   doc.text('Spirometer Reading', 10, 10);
  //   const element = document.getElementById('graphs');
  //   if (element) {
  //     html2canvas(element).then((canvas) => {
  //       const imgData = canvas.toDataURL('image/png');
  //       doc.addImage(imgData, 'PNG', 10, 20, 180, 160);
  //       doc.save('spirometer.pdf');
  //     });
  //   } else {
  //     doc.save('spirometer.pdf');
  //   }










  // generatePdf() {
  //   const element = document.getElementById('graphs'); // Ensure this is the correct container

  //   if (element) {
  //     // Capture the HTML content using html2canvas
  //     html2canvas(element).then((canvas) => {
  //       // Convert canvas to PNG image
  //       const imgData = canvas.toDataURL('image/png');

  //       // Create a Blob from the image data
  //       const byteCharacters = atob(imgData.split(',')[1]); // Get base64 content after "data:image/png;base64,"
  //       const byteArrays = [];
  //       for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
  //         const slice = byteCharacters.slice(offset, offset + 1024);
  //         const byteNumbers = new Array(slice.length);
  //         for (let i = 0; i < slice.length; i++) {
  //           byteNumbers[i] = slice.charCodeAt(i);
  //         }
  //         byteArrays.push(new Uint8Array(byteNumbers));
  //       }
  //       const blob = new Blob(byteArrays, { type: 'image/png' });

  //       // Create an object URL for the Blob
  //       const pngUrl = URL.createObjectURL(blob);
  //       // this.service.setImageUrlSparometer(pngUrl);


  //       // Store the PNG URL to display it in HTML
  //       this.pdfdatastore = pngUrl;
  //       console.log(this.pdfdatastore); // Log the PNG URL
  //       this.service.setImageUrlSparometer( this.pdfdatastore);

  //       // Optionally, display the PNG in HTML (example)
  //       const imgElement = document.createElement('img');
  //       imgElement.src = this.pdfdatastore;
  //       document.body.appendChild(imgElement); // You can append this to a specific container
  //     });
  //   } else {
  //     console.error('Element with ID "graphs" not found.');
  //   }
  // }






  // generatePdf() {
  //   const element = document.getElementById('graphs'); // Ensure this is the correct container

  //   if (element) {
  //     // Capture the HTML content using html2canvas
  //     html2canvas(element).then((canvas) => {
  //       // Convert canvas to PNG image (Base64)
  //       const imgData = canvas.toDataURL('image/png');

  //       // Remove the Base64 prefix (optional step if you want only the Base64 data)
  //       const base64Image = imgData.split(',')[1];

  //       // Send the image (Base64 string) to the backend
  //       this.uploadImageToDatabase(base64Image);

  //       // Optionally, display the PNG in HTML (example)
  //       const imgElement = document.createElement('img');
  //       imgElement.src = imgData; // This will display the image in the page
  //       document.body.appendChild(imgElement); // Append to a specific container or body
  //     }).catch((error) => {
  //       console.error('Error generating canvas:', error);
  //     });
  //   } else {
  //     console.error('Element with ID "graphs" not found.');
  //   }
  // }

  // // Function to send the Base64 image to the backend
  // private uploadImageToDatabase(base64Image: string) {
  //   // Assuming you have an API that accepts the Base64 string
  //   const imageData = {
  //     image: base64Image,  // Send the Base64 string as the image data
  //     otherData: 'some value', // Any other data you need to send
  //   };

  //   // Call your service to send the image to the backend
  //   this.service.setImageUrlSparometer(imageData)
  // }










  // generatePdf() {
  //   const element = document.getElementById('graphs'); // Ensure this is the correct container

  //   if (element) {
  //     // Capture the HTML content using html2canvas
  //     html2canvas(element).then((canvas) => {
  //       // Convert canvas to PNG image (Base64)
  //       const imgData = canvas.toDataURL('image/png');

  //       // Remove the Base64 prefix (optional step if you want only the Base64 data)
  //       const base64Image = imgData.split(',')[1];  // Extract only the Base64 string

  //       // Send the image (Base64 string) to the backend
  //       this.uploadImageToDatabase(base64Image);  // Pass the Base64 string

  //       // Optionally, display the PNG in HTML (example)
  //       const imgElement = document.createElement('img');
  //       imgElement.src = imgData; // This will display the image in the page
  //       document.body.appendChild(imgElement); // Append to a specific container or body
  //     }).catch((error) => {
  //       console.error('Error generating canvas:', error);
  //     });
  //   } else {
  //     console.error('Element with ID "graphs" not found.');
  //   }
  // }

  // // Function to send the Base64 image to the backend
  // private uploadImageToDatabase(base64Image: string) {
  //   const imageData = {
  //     image: base64Image,  // Send the Base64 string as the image data
  //     otherData: 'some value', // Any other data you need to send
  //   };

  //   // Call your service to send the image to the backend
  //   this.service.setImageUrlSparometer(imageData)
  // }







  // // it's working image data is going big
  //   generatePdf() {
  //     const element = document.getElementById('graphs'); // Ensure this is the correct container

  //     if (element) {
  //       // Capture the HTML content using html2canvas
  //       html2canvas(element).then((canvas) => {
  //         // Convert canvas to PNG image (Base64)
  //         const imgData = canvas.toDataURL('image/png');

  //         // Remove the Base64 prefix and extract only the Base64 string
  //         const base64Image = imgData.split(',')[1];  // Extract only the Base64 string (without the prefix)

  //         // Send the Base64 string to the backend
  //         this.uploadImageToDatabase(base64Image);  // Pass the Base64 string

  //         // Optionally, display the PNG in HTML (example)
  //         const imgElement = document.createElement('img');
  //         imgElement.src = imgData; // This will display the image in the page
  //         document.body.appendChild(imgElement); // Append to a specific container or body
  //       }).catch((error) => {
  //         console.error('Error generating canvas:', error);
  //       });
  //     } else {
  //       console.error('Element with ID "graphs" not found.');
  //     }
  //   }



  //   // Function to send the Base64 image to the backend
  // private uploadImageToDatabase(base64Image: string) {
  //   const imageData = {
  //     image: base64Image,  // Send the Base64 string as the image data
  //     otherData: 'some value', // Any other data you need to send
  //   };


  //   const arrayImgConvSparo: number[] = [];

  //     // Initialize urineBlob as null by default
  //     let sparometerBlob: Blob | null = null;

  //     if (imageData.image) {
  //       const base64ImageUrine = imageData.image.split(',')[1]; // Extract base64 part
  //       const binaryUrine = atob(base64ImageUrine); // Decode base64

  //       // Loop through the binary data and convert to character codes
  //       for (let i = 0; i < binaryUrine.length; i++) {
  //         arrayImgConvSparo.push(binaryUrine.charCodeAt(i));
  //       }

  //       // Create a Blob from the array of character codes
  //       sparometerBlob = new Blob([new Uint8Array(arrayImgConvSparo)], { type: 'image/png' });
  //     } else {
  //       // Handle case when no Urine data is available
  //       sparometerBlob = null; // Explicitly set to null


  //   // Call your service to send the image to the backend
  //   //setImageUrlSparometer
  //   this.service.setImageUrlSparometer(sparometerBlob);
  // }
  // }








  //it's working image data is going big
  /*
    generatePdf() {
      const element = document.getElementById('graphs'); // Ensure this is the correct container
    
      if (element) {
        // Capture the HTML content using html2canvas
        html2canvas(element).then((canvas) => {
          // Convert canvas to PNG image (Base64)
          const imgData = canvas.toDataURL('image/png');
    
          // Remove the Base64 prefix and extract only the Base64 string
          const base64Image = imgData.split(',')[1];  // Extract only the Base64 string (without the prefix)
    
          // Send the Base64 string to the backend
          this.uploadImageToDatabase(base64Image);  // Pass the Base64 string
    
          // Optionally, display the PNG in HTML (example)
          const imgElement = document.createElement('img');
          imgElement.src = imgData; // This will display the image in the page
          document.body.appendChild(imgElement); // Append to a specific container or body
        }).catch((error) => {
          console.error('Error generating canvas:', error);
        });
      } else {
        console.error('Element with ID "graphs" not found.');
      }
    }
  
    */

  /*
  
    // Function to send the Base64 image to the backend
  private uploadImageToDatabase(base64Image: string) {
    const imageData = {
      image: base64Image,  // Send the Base64 string as the image data
      otherData: 'some value', // Any other data you need to send
    };
  
  
    const arrayImgConvSparo: number[] = [];
  
      // Initialize urineBlob as null by default
      let sparometerBlob: Blob | null = null;
  
      if (imageData.image) {
        const base64ImageUrine = imageData.image.split(',')[1]; // Extract base64 part
        const binaryUrine = atob(base64ImageUrine); // Decode base64
  
        // Loop through the binary data and convert to character codes
        for (let i = 0; i < binaryUrine.length; i++) {
          arrayImgConvSparo.push(binaryUrine.charCodeAt(i));
        }
  
        // Create a Blob from the array of character codes
        sparometerBlob = new Blob([new Uint8Array(arrayImgConvSparo)], { type: 'image/png' });
      } else {
        // Handle case when no Urine data is available
        sparometerBlob = null; // Explicitly set to null
    
  
    // Call your service to send the image to the backend
    //setImageUrlSparometer
    this.service.setImageUrlSparometer(sparometerBlob);
  }
  }
  */


  // generatePdf() {
  //   const element = document.getElementById('graphs'); // Ensure this is the correct container

  //   if (element) {
  //     html2canvas(element).then((canvas) => {
  //       // Convert canvas to PNG image (Base64)
  //       const imgData = canvas.toDataURL('image/png');

  //       // Convert the Base64 to Blob
  //       const arrayImgConvSparo: number[] = [];
  //       const base64Image = imgData.split(',')[1]; // Extract only the Base64 part
  //       const binary = atob(base64Image); // Decode Base64

  //       for (let i = 0; i < binary.length; i++) {
  //         arrayImgConvSparo.push(binary.charCodeAt(i));
  //       }

  //       const sparometerBlob = new Blob([new Uint8Array(arrayImgConvSparo)], { type: 'image/png' });

  //       // Send Blob to service
  //       this.uploadImageToDatabase(sparometerBlob);
  //     }).catch((error) => {
  //       console.error('Error generating canvas:', error);
  //     });
  //   } else {
  //     console.error('Element with ID "graphs" not found.');
  //   }
  // }

  // private uploadImageToDatabase(sparometerBlob: Blob) {
  //   // Call your service to store the Blob
  //   this.service.setImageUrlSparometer(sparometerBlob);
  // }








  generatePdf() {
    const element = document.getElementById('graphs'); // Ensure this is the correct container
  
    if (element) {
      html2canvas(element).then((canvas) => {
        // Convert canvas to PNG image
        const imgData = canvas.toDataURL('image/png');
  
        // Create a Blob from the image data
        const blob = this.dataURLToBlob(imgData);
  
        // Store the Blob in your service
        this.service.setImageUrlSparometer(blob);
  
        console.log('Blob stored in service:', blob);
  
        // Optionally display the image in HTML for verification
        const imgElement = document.createElement('img');
        imgElement.src = URL.createObjectURL(blob);
        document.body.appendChild(imgElement);
      }).catch((error) => {
        console.error('Error capturing the element:', error);
      });
    } else {
      console.error('Element with ID "graphs" not found.');
    }
  }
  
  // Helper function to convert dataURL to Blob
  dataURLToBlob(dataURL) {
    const byteString = atob(dataURL.split(',')[1]); // Decode base64 string
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type
    const byteArrays = [];
  
    for (let offset = 0; offset < byteString.length; offset += 1024) {
      const slice = byteString.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
  
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      byteArrays.push(new Uint8Array(byteNumbers));
    }
  
    return new Blob(byteArrays, { type: mimeString });
  }
  



}